export const syncTask = async (remote_task_id, remote_task_url, alltasks, taskCache) => {
    const body = {
      "source_info": {
        "source_url": remote_task_url,
        "source_id": remote_task_id,
      }, 
    }
    if (alltasks) {
      body.descendants = alltasks || null
    }
 
    const results = await logFetchAJAX(getUrl(`/api/tasks/sync/`), {
        method: 'POST',
        body: body
    });

    var alltasks = results.descendants || {};
    for (const taskid in alltasks) {
      taskCache[taskid] = alltasks[taskid]
    }

    for (const taskid in alltasks) {
      hydrateTask(alltasks[taskid], taskCache)
    }

    hydrateTask(results["task"], taskCache)
    return results["task"]
}
